import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"

import { Container, Modal } from "react-bootstrap"
import FormFields from "../../static/forms/book_a_viewing.json"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import PropertyBanner from "../components/PropertyBanner/PropertyBanner"
import PropertyInformation from "../components/PropertyInformation/PropertyInformation"
import SimilarProperties from "../components/SimilarProperties/SimilarProperties"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import DefaultForm from "../components/forms/default-form-module"
import Seo from "../components/seo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import useLocation from "../hooks/useLocation"
import { GetTransformedUrl } from "../common/stb-jamstack-ggfxclient/client"


const PropertyDetails = props => {
  const PageData = props?.data?.allProperties?.edges[0]?.node
  const negotiatorData = props?.data?.strapiTeam
  const [shareIcons, setShareIcons] = useState(false)
  const [show, setShow] = useState(false)

  const [scrollValue, setScrollValue] = useState(0);
  const [isCallApi, isSetCallApi] = useState(false)

  const { isMobile, isLargeScreen } = useDeviceMedia()

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
  }

  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  const negoImage = negotiatorData?.face_image ? negotiatorData?.face_image:negotiatorData?.image

  const congifdata =
    props?.data?.allStrapiSiteConfig?.edges?.length > 0
      ? props?.data?.allStrapiSiteConfig?.edges[0]
      : null

  const phoneNum = negotiatorData?.phone
    ? negotiatorData?.phone :"97143025800"

  const popUpData =
    props?.data?.allStrapiSiteConfig?.edges?.length > 0
      ? props?.data?.allStrapiSiteConfig?.edges[0]?.node?.properties_popup
      : ""

  const image_url = negoImage ? negoImage : ""

  const name = negotiatorData?.name
    ? negotiatorData?.name
    : congifdata?.node?.office_name

  const dynamicData = {
    area: PageData?.area,
    department: PageData?.department,
    searchType: PageData?.search_type,
  }

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  useEffect(()=>{
    if (typeof window !== 'undefined') {
    const handleScroll = () =>{
      setScrollValue(window.scrollY);
    }
    window.addEventListener("scroll", handleScroll)

    return ()=>{
      window.removeEventListener("scroll", handleScroll)
    }
  }
  },[])

  useEffect(() => {
    if (scrollValue > 800 && !isCallApi) {
      isSetCallApi(true);
    }
  }, [scrollValue, isCallApi]);


  const negoWhatsapp = negotiatorData?.whatsapp?negotiatorData?.whatsapp?.replace(/[^A-Z0-9]/ig, ""):"97143025800"

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const propImage = PageData?.images?.length > 0 ? PageData?.images[0]?.url : ""
  const text = `Hi, I saw this property on haus & haus ${pageurl} and would like to know more.`
  return (
    
    <Layout
      popUpData={popUpData}
      dynamicData={dynamicData}
      dark="dark"
      footerClass="property-footer"
    >
      {/* <Head /> */}
      <div className="header-top"></div>
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            department={PageData?.department}
            alias={PageData?.search_type}
            type="property-details-page"
            pagename={PageData?.display_address}
          />
          <div
            className="share-property-details property"
            onClick={() => openShareicons()}
          >
            <i className="icon grey-share-icon" />
            <span>{isMobile ? "Share" : "Share Property"}</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={shareurl}
              />
            )}
          </div>
        </div>
      </Container>
      <PropertyBanner
        openGallery={props?.location?.state?.openGallery}
        PageData={PageData}
      />
      <PropertyInformation
        PageData={PageData}
        phoneNum={phoneNum}
        negotiator={negotiatorData}
        negoImage={negoImage}
        name={name}
        image_url={image_url}
      />
      {isCallApi&&
      <SimilarProperties {...PageData} />
      } 
      {/* {isLargeScreen && ( */}
        <div className="property-footer-ctas d-flex d-xl-none">
          {negoWhatsapp  && (
            <a
              className="whats-app"
              target="_blank"
              href={`https://wa.me/${negoWhatsapp?.replace(
                /[^A-Z0-9]/gi,
                ""
              )}?text=${encodeURIComponent(text)}`}
            >
              <button  aria-label="Contact through whatsapp" className="button button-filled-green">
                {" "}
                <i className="icon black-whatsapp" />
                {!isMobile && <span>Whatsapp</span>}
              </button>
            </a>
          )}

          <a href="javascript:void(0);" role="button" className="e_mail" onClick={handleModal}>
            <button  aria-label="Book a viewing cta" className="button button-outline-white">
              {" "}
              <i className="icon black-mail" /> <span>Book a Viewing</span>
            </button>
          </a>
          {
            <Modal
              show={show}
              // onHide={handleClose}
              backdrop="static"
              centered
              dialogClassName="modal-popup-form"
            >
              <Modal.Body>
                <div className="popup-form-wrapper">
                  <div className="close-modal" onClick={() => handleClose()}>
                    <i className="icon black-cancel-icon"></i>
                  </div>
                  <div>
                    <DefaultForm
                      fields={FormFields}
                      sourceUrl={pageurl}
                      prop_url={pageurl}
                      prop_img_url={propImage}
                      negoEmail={negotiatorData?.email}
                      prop_address={PageData?.display_address}
                      formTitle="Book a Viewing"
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          }

          {phoneNum && (
            <a className="call_num" href={`tel:${phoneNum}`}>
              <button aria-label="call us" className="button button-outline-white">
                {" "}
                <i className="icon black-call-icon" /> 
                {!isMobile && <span>Call</span>}
              </button>
            </a>
          )}
        </div>
      {/* )} */}
    </Layout>
  )
}

export const Head = props => {
  const pageData = props?.data?.allProperties?.edges[0]?.node
  //seo title, h1 and desc prepare, this can send to utilis function later
  let desc_meta_ptype =
    pageData?.building?.length > 0
      ? pageData?.building?.join(" and ")
      : "property"
  let department = pageData?.department
  let commercialType =
    pageData?.building?.length > 0
      ? pageData?.building?.join(" and ")
      : "properties"
  let cap_ptype =
    desc_meta_ptype?.charAt(0).toUpperCase() + desc_meta_ptype?.slice(1)
  let desc_meta_seachtype = `for sale`
  if (pageData?.search_type == "lettings") {
    desc_meta_seachtype = `to rent`
  }
  let desc_meta_beds = pageData?.bedroom
  let desc_meta_address = pageData?.display_address
  let desc_meta_price = `AED` + pageData?.price?.toLocaleString()
  let pagetitle =
    department === "commercial"
      ? `Commercial ${commercialType} ${desc_meta_seachtype} in ${desc_meta_address}`
      : `${cap_ptype} ${desc_meta_seachtype} with ${desc_meta_beds} bedrooms in ${desc_meta_address} at ${desc_meta_price}`
  let pagemetadesc =
    department === "commercial"
      ? `Book a viewing for this commercial ${commercialType} ${desc_meta_seachtype} in ${desc_meta_address}. Contact our properties specialists for more information about the properties.`
      : `Know the details of ${desc_meta_ptype} ${desc_meta_seachtype} with ${desc_meta_beds} bedrooms in ${desc_meta_address} at ${desc_meta_price}. Book a viewing with ${process.env.GATSBY_SITE_NAME} to get assistance in finding the right ${desc_meta_ptype} for you.`
  let pageUrl = typeof window !== "undefined" ? window.location.href : ""
  // +
  // (props?.location?.pathname).replace(/\/?$/, "/")
  let myimgtransforms = ""
  if (pageData?.imagetransforms?.images_Transforms) {
    myimgtransforms = JSON.parse(pageData?.imagetransforms?.images_Transforms)
  }

  let myimgtransforms1 = ""
  if (pageData?.imagetransforms?.metaog_Transforms) {
    myimgtransforms1 = JSON.parse(pageData?.imagetransforms?.metaog_Transforms)
  }
  

  let imageUrl=""

  const getImg=pageData?.images?.length>0?pageData?.images[0]?.url:""
  const findImage = getImg?.substring(getImg?.indexOf("i"));

  
  let imageExtension=getImg?getImg?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""
  
  if(myimgtransforms1){
    const ProcessedResult=findImage&&myimgtransforms1[findImage]?myimgtransforms1[findImage][ext]:""
    imageUrl=ProcessedResult?ProcessedResult["600x400"]:getImg
  }
  else if(myimgtransforms){
    // ogRender()
    const ProcessedResult=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
    imageUrl=ProcessedResult?ProcessedResult["336x220"]:getImg
  }

  const schemaAppImage1 =pageData?.images?.length>0 ? pageData?.images[0]?.url:""
  const schemaAppImage2 =pageData?.images?.length>1 ? pageData?.images[1]?.url:""
  const schemaAppImage3 =pageData?.images?.length>2 ? pageData?.images[2]?.url:""

  const apparmentBuilding = pageData?.building?.includes("Apartment")

  //appartment schema
   var appJson = {
      "@context": "https://schema.org",
      "@type": "Apartment",
      "@id": "Apartment",
      address: pageData?.display_address,
      description: pagemetadesc,
      identifier: pageData?.title,
      image: [
        schemaAppImage1,schemaAppImage2,schemaAppImage3
      ],
      keywords: pageUrl,
      mainEntityOfPage: pageUrl,
      numberOfBathroomsTotal: pageData?.bathroom,
      numberOfBedrooms: pageData?.bedroom,
      numberOfFullBathrooms: pageData?.bathroom,
      url: pageUrl
   }

  //

  //commercial
  const commercialJson = {
    "@context": "https://schema.org",
    "@type": "Place",
    "@id": "Place",
    "name": pageData?.community,
    "address": {
    "@type": "PostalAddress",
    "streetAddress": pageData?.area,
    "addressLocality": "Dubai",
    "addressCountry": "UAE"
    },
    "geo": {
    "@type": "GeoCoordinates",
    "latitude": pageData?.latitude,
    "longitude": pageData?.longitude
    },
    "url": pageUrl,
    "image": schemaAppImage1,
    "description": pagemetadesc,
    "amenityFeature": [
      {
       "@type": "LocationFeatureSpecification",
        "name": "Parking",
        "value": true
      },
      {
      "@type": "LocationFeatureSpecification",
      "name": "Elevator",
      "value": true
      },  
    {
    "@type": "LocationFeatureSpecification",
    "name": "Security",
    "value": true
    }
    ]
    }
  //

  let ldJson = {
    "@context": "https://schema.org",
    "@type": "SingleFamilyResidence",
    "@id": "SingleFamilyResidence",
    name: `${pageData?.title} in ${pageData?.display_address} `,
    url: pageUrl,
    description: pagemetadesc,
    address: {
    "@type": "PostalAddress",
    streetAddress: pageData?.address?.address1,
    addressLocality: pageData?.address?.address2,
    addressRegion: "Dubai",
    addressCountry: "AE"
    },
    numberOfRooms: pageData?.bedroom,
    floorSize: {
    "@type": "QuantitativeValue",
    value: pageData?.floorarea_min,
    unitCode: "SQFT"
    },
    image: schemaAppImage1,
    potentialAction: {
    "@type": "ReserveAction",
    target: {
      "@type": "EntryPoint",
      urlTemplate: "https://www.hausandhaus.com/contact/"
      }
    },
    amenityFeature: pageData?.special?.length>0?pageData?.special:""
  }

  let imageObjectScript = {
    "@context": "https://schema.org",	
    "@type": "ImageObject",	
    "@id": "ImageObject",
    contentUrl: schemaAppImage1,
    url: pageUrl,
    name: `${pageData?.title} in ${pageData?.display_address}`,	
    description: pagemetadesc,
    height: "680",	
    width: "1008",	
    author: {	
      "@type": "Organization",	
      name: "haus & haus"	
    }
  }

  //breadscrumb schema

  let dynamicmenu1 ="Home"
  let dynamicmenu1Url = "https://www.hausandhaus.com/"
  let dynamicmenu2 = ""
  let dynamicmenu2Url=""
  let dynamicmenu3 = pageData?.display_address

  let statusVal = "properties/for-sale/in-dubai"
  if(pageData?.search_type === "lettings" & pageData?.department === "residential"){
    statusVal = "properties-to-rent"
  }
  else if(pageData?.search_type === "sales" & pageData?.department === "commercial"){
    statusVal = "commercial-properties/for-sale/in-dubai"
  }
  else if(pageData?.search_type === "lettings" & pageData?.department === "commercial"){
    statusVal = "commercial-properties/to-rent/in-dubai"
  }
  else{
    statusVal = "properties/for-sale/in-dubai"
  }

  let dynamicmenu3Url = `${process.env.GATSBY_SITE_URL}/${statusVal}/${pageData?.slug}/${pageData?.strapi_id}/`
  

  if( pageData.department==="new_developments"){
    dynamicmenu2 = "Off Plan for Sale";
    dynamicmenu2Url = `${process.env.GATSBY_SITE_URL}/off-plan/properties/for-sale/in-dubai/`
  }
  else if(pageData.search_type === "sales" && pageData.department === "residential"){
    dynamicmenu2 = "Property for sale in Dubai"
    dynamicmenu2Url = `${process.env.GATSBY_SITE_URL}/properties/for-sale/in-dubai/`
  }
  else if(pageData.search_type === "lettings" && pageData.department === "residential"){
    dynamicmenu2 = "Property to rent"
    dynamicmenu2Url = `${process.env.GATSBY_SITE_URL}/properties/to-rent/in-dubai/`
  }
  else if(pageData?.search_type === "sales" && pageData.department === "commercial"){
    dynamicmenu2 = "Commercial property for sale in Dubai"
    dynamicmenu2Url = `${process.env.GATSBY_SITE_URL}/commercial-properties/for-sale/in-dubai/`
  }
  else {
    dynamicmenu2 = "Commercial property to rent in Dubai"
    dynamicmenu2Url = `${process.env.GATSBY_SITE_URL}/commercial-properties/to-rent/in-dubai/`
  }

  let breadcrumbJson = {
    "@context": "https://schema.org",	
  "@type": "BreadcrumbList",	
  "@id": "BreadcrumbList",
  itemListElement: [	
    {	
      type: "ListItem",	
      position: 1,	
      name: dynamicmenu1,	
      item: dynamicmenu1Url	
    },	
  ]	
  }

  if(dynamicmenu2){
    breadcrumbJson.itemListElement.push(
      {
        type: "ListItem",	
        position: 2,	
        name: dynamicmenu2,	
        item: dynamicmenu2Url	
      }
    )
  }

  if(dynamicmenu3 && dynamicmenu3Url){
    breadcrumbJson.itemListElement.push(
      {
        type: "ListItem",	
        position: 3,	
        name: dynamicmenu3,	
        item: dynamicmenu3Url	
      }
    )
  }

  //
  

  var { pathname } = useLocation()
  var curentUrl = ""
  curentUrl = process.env.GATSBY_SITE_URL + pathname
  // check trailing slash
  if (!curentUrl.endsWith("/")) curentUrl = curentUrl + "/"
  // remove page- from url
  if (curentUrl.includes("page-")) {
    curentUrl = curentUrl
      .split("/")
      .filter(path => !path.includes("page-"))
      .join("/")
  }
  //remove multiple slashes
  curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1")
  //set lowercase url
  curentUrl = curentUrl.toLowerCase()

  //new ggfx image
  const getImage =
  pageData?.images?.length > 0 && pageData?.images[0].url
      ? pageData?.images[0].url.replace(
          "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
          "",
        )
      : ""
  const getImage1 =
  pageData?.images?.length > 1 && pageData?.images[1].url
      ? pageData?.images[1].url.replace(
          "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
          "",
        )
      : ""
  const getImage2 =
  pageData?.images?.length > 2 && pageData?.images[2].url
      ? pageData?.images[2].url.replace(
          "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
          "",
        )
      : ""
  const filterImage =
    getImage && pageData?.ggfx_results?.length > 0
      ? pageData?.ggfx_results?.filter((item) => item.src_cftle === getImage)
      : ""

  const filterImage1 = getImage1 && pageData?.ggfx_results?.length > 0
  ? pageData?.ggfx_results?.filter((item) => item.src_cftle === getImage1)
  : ""

  const filterImage2 = getImage2 && pageData?.ggfx_results?.length > 0
  ? pageData?.ggfx_results?.filter((item) => item.src_cftle === getImage2)
  : ""

  const preloadImage = filterImage.length > 0 && filterImage[0].transforms?.length > 0
  ? filterImage[0].transforms.filter((item) => item.transform === "1008x680")
  : ""

  const preloadImage1 = filterImage1.length > 0 && filterImage1[0].transforms?.length > 0
  ? filterImage1[0].transforms.filter((item) => item.transform === "1008x680")
  : ""

  const preloadImage2 = filterImage2.length > 0 && filterImage2[0].transforms?.length > 0
  ? filterImage2[0].transforms.filter((item) => item.transform === "1008x680")
  : ""

  // filter image with image size
  const getOGImg =
    filterImage.length > 0 && filterImage[0].transforms?.length > 0
      ? filterImage[0].transforms.filter((item) => item.transform === "600x400"&&item.format===ext)
      : ""
  
  const webPImage = filterImage.length > 0 && filterImage[0].transforms?.length > 0
  ? filterImage[0].transforms.filter((item) => item.transform === "696x394")
  : ""

  const defaultImage = filterImage.length > 0 && filterImage[0].transforms?.length > 0
  ? filterImage[0].transforms[0]?.url:""



   //to get image in uploaded format   
  const ogRenderNew = ()=>{
    GetTransformedUrl({
      srcCftle: findImage,
      format: ext,
      transform:"600x400",
      id: pageData.strapi_id,
      field: "metaog",
      contentType: "property"
    })
  }
  // if(!getOGImg?.length>0){
  //   ogRenderNew()
  // } 

  useEffect(()=>{
    if(!getOGImg?.length>0)
        ogRenderNew()
  },[]) 

  //----

  const pageImage=getOGImg?.length>0?getOGImg[0].url:""
  const fallBackImage = webPImage?.length > 0 ? webPImage[0].url:""
  //-------

  const jsonValue = pageData?.department === "commercial"? commercialJson :
  apparmentBuilding?appJson:ldJson
  const metaImage = pageData?.algoliaData?.metaog?.length>0?pageData?.algoliaData?.metaog[0]["_600x400"]:""
  return (
    <>
    <link rel="preload" href={preloadImage?.length>0&&preloadImage[0]?.url} as="image" type="image/webp"  /> 
    {/* <link rel="preload" href={preloadImage1?.length>0&&preloadImage1[0]?.url} as="image" type="image/webp"  />  */}
    {/* <link rel="preload" href={preloadImage2?.length>0&&preloadImage2[0]?.url} as="image" type="image/webp"  />  */}

    <Seo title={pagetitle + " | "} description={pagemetadesc} imageUrl={metaImage?metaImage:pageImage?pageImage:fallBackImage?fallBackImage:defaultImage?defaultImage:imageUrl}
     status={pageData?.status}
    >
       <script
        type="application/ld+json"
        >
          {JSON.stringify(jsonValue, null, 2)}
        </script>
       <script
        type="application/ld+json"
        >
          {JSON.stringify(imageObjectScript, null, 2)}
        </script>
       <script
        type="application/ld+json"
        >
          {JSON.stringify(breadcrumbJson, null, 2)}
        </script>
       
    </Seo>
    </>
  )
}

export const query = graphql`
  query ($strapi_id: Int, $negotiator_id: String) {
    strapiTeam(negotiator_id: { eq: $negotiator_id }) {
      designation
      email
      image {
        url
      }
      face_image{
        url
      }
      tile_image {
        url
      }
      imagetransforms {
        image_Transforms
      }
      Languages_Spoken {
        strapi_json_value
      }
      ggfx_results {
        src_cftle
        transforms {
          url
          format
          transform
        }
      }
      name
      phone
      slug
      whatsapp
      strapi_id
      negotiator_id
    }
    allProperties(filter: { strapi_id: { eq: $strapi_id } }) {
      edges {
        node {
          title
          negotiator_id
          crm_id
          area
          slug
          department
          latitude
          longitude
          status
          new_development
          price
          bedroom
          building
          address {
            address1
            address2
            address3
            address4
          }
          display_address
          accommodation_summary
          long_description
          description
          bathroom
          imagetransforms {
            images_Transforms
            metaog_Transforms
          }
          introtext
          extra {
            completion_status
            payment_type
            plot_area_min
            extrasField{
              pNoOfCheqs
            }
          }
          algoliaData {
            metaog {
              _600x400
              url
            }
          }
          ggfx_results {
            src_cftle
            transforms {
              url
              format
              transform
            }
          }
          images {
            url
            srcUrl
          }
          video_tour {
            url
          }
          qr_code {
            validationQr
            listingNumber
          }
          brochure {
            srcUrl
          }
          virtual_tour {
            url
          }
          search_type
          strapi_id
          floorarea_type
          floorarea_min
          crm_negotiator_id {
            email
            job_title
            mobile_phone
            name
            profile_img
            work_phone
          }
          community
          special
        }
      }
    }
    allStrapiSiteConfig {
      edges {
        node {
          office_name
          office_image {
            url
          }
          head_office_number
          properties_popup {
            time_count
            title
            show_popup
            image {
              url
            }
            cta {
              custom_link
              link_type
              title
              link {
                slug
                strapi_parent {
                  slug
                }
              }
            }
            content {
              data {
                content
              }
            }
          }
        }
      }
    }
  }
`

export default PropertyDetails
